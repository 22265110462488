export interface IAutenticated {
	data: IData;
	error: boolean;
  client: IClient;
  canal: string;
}

export interface IAutenticatedT{
	data: IData[];
	error: boolean;
}
export interface IData {
	documentType: string;
	documentNumber: string;
	lastEntryHour: string;
	lastEntryDate: string;
	ipClient: string;
	customerName: string;
  sessionId: string;
  sessionToken: string;
  transactionToken: string;
  lastLoginDate?: string;  
  lastLoginHour?: string;
}

export interface IClient {
  customerName : string;
  email  : string;
  firstName  : string;
  firstSurname  : string;
  mobilePhone  : string;
}

export interface IAutenticatedRedirection{
  state : string;
  experiencia : string;
  data: IData;
	error: boolean;
}

export const initialStateAutenticated: IAutenticated = {
	data:{
		documentType: "",
		documentNumber: "",
		lastEntryHour: "",
		lastEntryDate: "",
		ipClient: "",
		customerName: "",
    sessionId:"",
    sessionToken: "NOTVALID",
    transactionToken: ""
	},
	error: false,
  canal : "",
  client: {
    customerName : "",
    email  : "",
    firstName  : "",
    firstSurname  : "",
    mobilePhone  : ""
  }
};

export const initialStateIData: IData = {
  documentType: "",
  documentNumber: "",
  lastEntryHour: "",
  lastEntryDate: "",
  ipClient: "",
  customerName: "",
  sessionId:"",
  sessionToken: "",
  transactionToken:""
};

export const initialStateAutenticatedPrueb: IAutenticated = {
	data:{
		documentType: "",
		documentNumber: "",
		lastEntryHour: "",
		lastEntryDate: "",
		ipClient: "",
		customerName: "PACO",
    sessionId:"",
    sessionToken: "",
    transactionToken:""
	},
	error: false,
  canal : "",
  client: {
    customerName : "",
    email  : "",
    firstName  : "",
    firstSurname  : "",
    mobilePhone  : ""
  }
};
