export interface IMntUserData {
  installmentLifeAndUnemployment: string;
  expirationDate: string;
  loanNumber: string;
  loanPlanDescription: string;
  regularInstallmentClean: string;
  optionSelect: string;
  dateSimulation: string;
  nextPaymentDate: string;
  extension: string;
  termExtension: string;
}



export const initialMntUserData: IMntUserData = {
  installmentLifeAndUnemployment: '',
  expirationDate: '',
  loanNumber: '',
  loanPlanDescription: '',
  regularInstallmentClean: '',
  optionSelect: '',
  dateSimulation: '',
  nextPaymentDate: '',
  extension: '',
  termExtension: ''
};
