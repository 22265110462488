import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { IAppState } from "src/app/store/states/app.state";
import { IData } from "src/app/layout/cobranza-digital/store/states/autenticated.state";
import { filter } from "rxjs/operators";
import { DatePipe } from "@angular/common";

@Component({
	selector: "app-footer",
	templateUrl: "./footer.component.html",
	styleUrls: ["./footer.component.scss"],
	providers: [DatePipe]
})
export class FooterComponent implements OnInit {
	datax: IData;
	maintenancedark: boolean = false;
	currentDate: Date = new Date();
	dark: boolean = false;
	isFirma: boolean = false;
	isMantenimientos: boolean = false;
	lastLoginHour: string = "";
	lastLoginDate: string = "";
	ipClient: string = "";
	formattedLastLogin: string = "";

	constructor(
		private readonly _store: Store<IAppState>,
		private readonly router: Router,
		private datePipe: DatePipe
	) {}

	ngOnInit(): void {
		this.getAutenticatedStore();
		this.currentDate = new Date();

		this.router.events
			.pipe(filter((event) => event instanceof NavigationEnd))
			.subscribe(() => {
				this.isMantenimientos =
					this.router.url.includes("mantenimientos");
			});

		this.formattedLastLogin = this.getFormattedDateTime(this.lastLoginDate);
	}

	getAutenticatedStore() {
		this._store
			.select((state) => state.autenticated.data)
			.subscribe((response) => {
				this.datax = response;
				if (response) {
					this.lastLoginHour =
						response.lastLoginHour || "No disponible";
					this.lastLoginDate =
						response.lastLoginDate || "No disponible";
					this.ipClient = response.ipClient || "No disponible";
				}
			})
			.unsubscribe();
	}

	getFormattedDate(): string {
		const options = {
			year: "numeric",
			month: "long",
			day: "numeric"
		} as Intl.DateTimeFormatOptions;
		return this.currentDate.toLocaleDateString("es-ES", options);
	}

	getFormattedDateTime(date: string): string {
		const parsedDate = new Date(date);
		if (isNaN(parsedDate.getTime())){
			return 'No disponible';
		}
		return this.datePipe.transform(parsedDate, 'EEEE d \'de\' MMMM \'de\' y', 'es-ES');
	}

	get hiddenNav(): boolean {
		const currentURL = document.location.href;
		if (currentURL.includes("firmaelectronica")) {
			this.isFirma = true;
		}
		const hiddenNavbar = [
			"beneficiarios",
			"asegurabilidad",
			"aceptar-documento"
		];
		return !hiddenNavbar.some((element) => currentURL.includes(element));
	}
}
