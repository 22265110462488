import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of, BehaviorSubject } from "rxjs";
import { catchError, switchMap, tap } from "rxjs/operators";
import { MaintenanceInfo } from "../layout/mantenimientos/store/states/Maintenance-Info.state";
import { environment } from "src/environments/environment";
import { momento, typeDcmt } from "../utils/utils";
import { Store } from "@ngrx/store";
import { IAppState } from "../store/states/app.state";
import {
	IInfoUserForLog,
	Ilog,
	bodyLog,
	functionalStepsMtts,
	initialInfoUserForLog
} from "../utils/utilsMaintenance";
import { ILogResponse } from "../interfaces/responses.interface";
import {
	IData,
	initialStateAutenticated
} from "src/app/layout/cobranza-digital/store/states/autenticated.state";
import {
	LoadAutenticatedFailed,
	LoadAutenticatedSuccess
} from "../layout/cobranza-digital/store/actions/autenticated.action";
import { DuplicateSession } from "../layout/cobranza-digital/store/actions/authorize.action";
import { NavigationStart, Router } from "@angular/router";

@Injectable({
	providedIn: "root"
})
export class MantenimientosService {
	urlMaintenanceinfo: string;
	private document = "";
	private documentType = "";
	private obligaciones: {};
	private readonly encuestaEjecutada = new BehaviorSubject<boolean>(false);
	encuestaEjecutada$ = this.encuestaEjecutada.asObservable();
	private readonly emailIngresado = new BehaviorSubject<boolean>(false);
	emailIngresado$ = this.emailIngresado.asObservable();
	private readonly blockedRoutes = ["/listado-creditos", "/confirmacion-negociacion"];
	isBlocked = false;

	constructor(
		private readonly http: HttpClient,
		private readonly _store: Store<IAppState>,
		private readonly router: Router
	) {
		this.urlMaintenanceinfo = environment.urlMaintenance_info;
		this._store
			.select((state) => state.autenticated)
			.subscribe((autenticated) => {
				this.document = autenticated.data?.documentNumber ?? "";
				this.documentType = autenticated.data?.documentType ?? "";
			});

		this.router.events.subscribe((event) => {
			if (event instanceof NavigationStart) {
				this.isBlocked = this.blockedRoutes.includes(event.url);
			}
		});

		window.onpopstate = () => {
			if (this.isBlocked) {
				history.pushState(null, "", window.location.href);
			}
		};
	}

	enableBlocking() {
		history.pushState(null, "", window.location.href);
		window.onpopstate = () => {
		  history.pushState(null, "", window.location.href);
		};
	  }

	getHeader = () => {
		let transactionToken = "";
		let sessionToken = "";
		this._store
			.select((state) => state.autenticated.data)
			.subscribe((data) => {
				transactionToken = data.transactionToken;
				sessionToken = data.sessionToken;
			})
			.unsubscribe();
		return new HttpHeaders({
			"Content-Type": "application/json",
			"Content-Encoding": "gzip, deflate, br",
			jwtInput: transactionToken,
			jwtFua: sessionToken
		});
	};

	getDataUser(documentType: string, documentNumber: string) {
		return this.http
			.post<MaintenanceInfo[]>(
				`${this.urlMaintenanceinfo}/cobranza-digital/ms-edge/ms-offer/maintenance/consult`,
				{
					documentType: +typeDcmt(documentType),
					documentNumber: documentNumber
				},
				{
					headers: this.getHeader()
				}
			)
			.pipe(
				catchError((error: any) => {
					const functionalErrorCode =
						error.error?.errorDetails?.functionalErrorCode;
					if (
						error.error == null ||
						(functionalErrorCode !== "OFFER025" &&
							functionalErrorCode !== "OFFER022")
					) {
						this.registerLog(
							functionalStepsMtts.LISTADO_OBLIGACIONES,
							error
						).subscribe();
					}
					this.dispatchDuplicateSession(functionalErrorCode);
					const pattern = /\(([^)]+)\)/;
					const matchResult = pattern.exec(
						error.error?.errorDescription || ""
					);
					const hours = matchResult
						? matchResult[1]
						: "04:00 a.m.-09:00 p.m.";
					sessionStorage.setItem("hours", hours);
					return of({
						codeError:
							error.error?.errorDetails?.functionalErrorCode ?? ""
					});
				})
			);
	}

	getSimulation(requestSimulation: any) {
		return this.http
			.post<any>(
				`${this.urlMaintenanceinfo}/cobranza-digital/ms-edge/ms-offer/maintenance/simulate`,
				requestSimulation,
				{
					headers: this.getHeader()
				}
			)
			.pipe(
				catchError((error: any) => {
					this.registerLog(
						functionalStepsMtts.SELECCIONAR_OPCION_PAGO,
						error
					).subscribe();
					this.dispatchDuplicateSession(
						error.error?.errorDetails?.functionalErrorCode
					);
					return of({
						codeError:
							error.error?.errorDetails?.functionalErrorCode ?? ""
					});
				})
			);
	}

	acceptTyC(data: any) {
		return this.http
			.post<any>(
				`${this.urlMaintenanceinfo}/cobranza-digital/ms-edge/ms-offer/maintenance/accept-alternative`,
				data,
				{
					headers: this.getHeader()
				}
			)
			.pipe(
				catchError((error: any) => {
					this.registerLog(
						functionalStepsMtts.ACEPTAR_TYC,
						error
					).subscribe();
					this.dispatchDuplicateSession(
						error.error?.errorDetails?.functionalErrorCode
					);
					return of({
						codeError:
							error.error?.errorDetails?.functionalErrorCode ?? ""
					});
				})
			);
	}

	getClientServiceOK(
		documentType: string,
		documentNumber: string
	): Observable<any> {
		return this.http
			.post<any>(
				`${this.urlMaintenanceinfo}/cobranza-digital/ms-edge/ms-client/client`,
				{
					documentType: +typeDcmt(documentType),
					documentNumber: documentNumber
				},
				{
					headers: this.getHeader()
				}
			)
			.pipe(
				catchError((error: any) => {
					this.registerLog("2A_Ver_Bienvenida", error).subscribe();
					return of({
						codeError:
							error.error?.errorDetails?.functionalErrorCode ?? ""
					});
				})
			);
	}

	registerLog(
		functionalStep: string,
		error: any = null,
		newTelephone?: string
	): Observable<ILogResponse> {
		const bodyRegisterLog = this.createBodyRegisterLog(
			functionalStep,
			error,
			newTelephone
		);
		return this.http
			.post<any>(
				`${this.urlMaintenanceinfo}/cobranza-digital/ms-edge/ms-logs/register`,
				bodyRegisterLog,
				{
					headers: this.getHeader()
				}
			)
			.pipe(
				catchError((error) => {
					this.dispatchDuplicateSession(
						error.error?.errorDetails?.functionalErrorCode
					);
					return of("The service register log has failed");
				})
			);
	}

	createBodyRegisterLog(
		functionalStep: string,
		error: any,
		newTelephone?: string
	): Ilog {
		const dataLog = this.getDataLog(functionalStep, newTelephone);
		return bodyLog(dataLog, error);
	}

	getDataLog(functionalStep: string, newTelephone?: string) {
		const dataLog: IInfoUserForLog = initialInfoUserForLog;
		this._store
			.select((state) => state.autenticated)
			.subscribe((authentication) => {
				dataLog.sessionId = authentication.data.sessionId;
				dataLog.ipClient = authentication.data.ipClient;
				dataLog.pasoFuncional = functionalStep;
				dataLog.documentType = authentication.data.documentType;
				dataLog.documentNumber = authentication.data.documentNumber;
				dataLog.customerName = authentication.client.customerName;
				dataLog.mobilePhone =
					newTelephone ?? authentication.client.mobilePhone;
			})
			.unsubscribe();
		this._store
			.select((state) => state.mntUserData)
			.subscribe((mntUserData) => {
				dataLog.optionSelected = mntUserData.optionSelect;
				dataLog.loanNumber = mntUserData.loanNumber;
			})
			.unsubscribe();
		return dataLog;
	}

	logoutService() {
		const emtyData = initialStateAutenticated;

		let sessionToken: string, documentNumber: string;
		this._store
			.select((state) => state.autenticated.data)
			.subscribe((response: IData) => {
				sessionToken = response.sessionToken;
				documentNumber = response.documentNumber;
			})
			.unsubscribe();
		const bodyLogOut: any = {
			accessToken: sessionToken,
			documentNumber: documentNumber
		};

		return this.http
			.post(
				`${this.urlMaintenanceinfo}/cobranza-digital/ms-edge/ms-auth/logOut`,
				bodyLogOut,
				{
					headers: this.getHeader(),
					observe: "response"
				}
			)
			.pipe(
				tap(() => {
					this._store.dispatch(new LoadAutenticatedFailed(true));
					this._store.dispatch(new LoadAutenticatedSuccess(emtyData));
				}),
				catchError((error) => {
					return of("The service has failed");
				})
			);
	}

	private dispatchDuplicateSession(functionalErrorCode: string) {
		if (functionalErrorCode === "AUTH010") {
			this._store.dispatch(new DuplicateSession(true));
		}
	}

	sendInterestManagement(
		numberPhone: any,
		pasoFuncional: string
	): Observable<any> {
		this.loadObligaciones();

		const fechaConvertida = this.getFormatteDate();
		const data = {
			documentType: typeDcmt(this.documentType),
			documentNumber: this.document,
			mobilPhone: numberPhone,
			acceptanceDate: fechaConvertida,
			loans: this.obligaciones
		};
		return this.http
			.post<any>(
				`${this.urlMaintenanceinfo}/cobranza-digital/ms-edge/ms-offer/maintenance/interest-management`,
				data,
				{
					headers: this.getHeader()
				}
			)
			.pipe(
				catchError((error: any) => {
					return this.registerLog(pasoFuncional, error).pipe(
						switchMap(() => {
							return of({
								codeError:
									error.error?.errorDetails
										?.functionalErrorCode ?? ""
							});
						})
					);
				})
			);
	}

	private loadObligaciones() {
		this._store
			.select(
				(state) => state.alternativesInCache.customerData.obligaciones
			)
			.subscribe((data) => {
				this.obligaciones = data;
			});
	}

	private getFormatteDate() {
		const uno = 1;
		const dos = 2;
		const cero = 0;
		const fechaMomento = momento();
		const partes = fechaMomento.split(" ");
		const fecha = partes[0].split("/");
		const hora = partes[1];
		return `${fecha[dos]}-${fecha[uno]}-${fecha[cero]} ${hora}`;
	}

	setEncuestaEjecutada(valor: boolean) {
		this.encuestaEjecutada.next(valor);
	}

	setEmailIngresado(valor: boolean) {
		this.emailIngresado.next(valor);
	}
}
