import {
	AutenticatedActions,
	EAutenticated
} from "../actions/autenticated.action";
import { initialStateAutenticated } from "../states/autenticated.state";

function loadAutenticatedSuccess(state, action) {
	const { data, canal } = action.payload;
	return {
		...state,
		data,
		canal,
		error: false
	}
}

function loadClientSuccess(state, action) {
	return {
		...state,
		client: { ...action.payload }
	};
}

function loadAutenticatedFail(state) {
	return {
		...state,
		error: true
	};
}

function loadEmail(state, action) {
	return {
		...state,
		client: {
			...state.client,
			email: action.payload
		}
	};
}

export function autenticatedReducer(
	state = initialStateAutenticated,
	action: AutenticatedActions
) {
	switch (action.type) {
		case EAutenticated.LOAD_AUTENTICATED_SUCCESS: 
			return loadAutenticatedSuccess(state, action);
		case EAutenticated.LOAD_CLIENT_SUCCESS: 
			return loadClientSuccess(state, action);
		case EAutenticated.LOAD_AUTENTICATED_FAIL: 
			return loadAutenticatedFail(state);
		case EAutenticated.LOAD_EMAIL: 
			return loadEmail(state, action);
		default:
			return state;
	}
}
