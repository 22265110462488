import { Component } from '@angular/core';
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { IAppState } from 'src/app/store/states/app.state';
import { pinblocksGen } from "src/app/utils/utils";
import { CobranzaService } from "src/app/services/cobranza.service";
import { IClientOk } from '../../cobranza-digital/store/states/clientOk.state';
import { LoadClientOkFailed } from '../../cobranza-digital/store/actions/clientOk.action';
import { catchError, tap } from 'rxjs/operators';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class BannerComponent {
  idDocument : string = "";
  typeDc : string = "";
  public origin: string = "";
  origintrace = null;

  constructor(
    private readonly cobranzaService: CobranzaService,
    private readonly router: Router,
		private readonly _store: Store<IAppState>,
  ) { }

  ngOnInit(): void {
		sessionStorage.clear();

  }

  clickSimulacion(){
    if(this.idDocument !== "" && this.typeDc !== "" && this.origin !==""){
      this.cobranzaService.generateAuthCode(this.idDocument, pinblocksGen(this.idDocument), this.typeDc)
      .pipe(
        catchError((e: IClientOk) => {
          this._store.dispatch(new LoadClientOkFailed(e));
          this.router.navigate(["/error"]);
          throw e;
        })
      )
      .subscribe({
        next: (response) => {
          const cod: string = response['authorizationCode'];
          if(this.origin==="0"){
            this.origintrace = "&trace=pruebatrace"
          }

          this.router.navigate([""], {
            queryParams: {
              code: cod,
              trace: this.origintrace
            }
          });
        }
      });
  }

  }
  onChangeValue($event:string){
    this.origin = $event;
  }
}
