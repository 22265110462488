import Bowser from "bowser";
import { disp, momento, typeDcmt } from "./utils";

export const functionalStepsMtts = {
	LISTADO_OBLIGACIONES: "Listado_Obligaciones",
	SELECCIONAR_OPCION_PAGO: "Seleccionar_Opcion_pago",
	SELECCIONAR_CONDICIONES: "Seleccionar_Condiciones",
	NUEVAS_CONDICIONES: "Nuevas_Condiciones",
	ACEPTAR_TYC: "Aceptar_TyC",
	CONFIRMACION_ALTERNATIVA: "Confirmacion_Alternativa",
	BUSCAR_OTRA_OPCION: "Buscar_Otra_Opcion",
	SOLICITAR_LLAMADA: "Solicitar_Llamada",
	LLAMAME: "Llamame",
	CLIENTE_AL_DIA: "Cliente_Al_Dia",
  ACTUALIZAR_CONTACTO: "Actualizar_Contacto"
};

const optionsMap = {
  "": "",
	"2": "Aplazar Pago",
	"3": "Aplazar Pago y disminuir cuota"
};

export const bodyLog = (dataLog: IInfoUserForLog, error: any): Ilog => {
	const fechaYhora: string = momento();
	const bowser = Bowser.getParser(navigator.userAgent);
  const canal = sessionStorage.getItem("trace");
  const optionSelectedStr = optionsMap[dataLog.optionSelected];
	let errorSection: any;
	if (error?.error?.errorDetails) {
		errorSection = {
			codigoError: error.error.errorDetails.functionalErrorCode,
			descripcionError: error.error.errorDescription,
			codigoErrorFuncional: error.error.errorDetails?.functionalErrorCode??"",
			descripcionErrorFuncional:
			error.error.errorDetails.functionalErrorDescription,
			tipoExcepcion: "TECNICO",
			servicio: error.error.errorDetails.serviceName,
			operacionServicio: error.error.errorDetails.operationName
		};
	}else{
    errorSection = {
			codigoError: error? "ERROR"+error.status : "",
			descripcionError: "",
			codigoErrorFuncional:  error? "ERROR"+error.status : "",
			descripcionErrorFuncional: "",
			tipoExcepcion: error? "TECNICO": dataLog.loanNumber,
			servicio: error? "Cobranza digital" : optionSelectedStr,
			operacionServicio: error? "Cobranza digital" : "CD_Mantenimiento"
		};
  }
	return {
		technicalSection: getTechnicalSection(dataLog, fechaYhora, canal, bowser),
		clientSection: getClientSection(dataLog),
		errorSection: errorSection
	};
};

const getTechnicalSection = (dataLog: IInfoUserForLog, fechaYhora: string, canal: string, bowser: any) =>{
	return {
		idSesion: dataLog.sessionId,
		ipClient: dataLog.ipClient,
		fechaHoraTransaccion: fechaYhora,
		pasoFuncional: dataLog.pasoFuncional,
		canal: canal != "null" || null? "SITIO_CONTENIDOS" :  "APP_PERSONAS",
		deviceBrowser: bowser.getBrowserName(),
		sistemaOperativo: bowser.getOSName(),
		dispositivo: disp(bowser.getPlatformType())
	}
}

const getClientSection = (dataLog: IInfoUserForLog) => {
	return {
		tipoDocumentoId: typeDcmt(dataLog.documentType),
		numeroDocumento: dataLog.documentNumber,
		nombreCortoCliente: dataLog.customerName,
		telefonoCelular: dataLog.mobilePhone
	}
}

export interface Ilog {
	technicalSection: {
		idSesion: string;
		ipClient: string;
		fechaHoraTransaccion: string;
		pasoFuncional: string;
		canal: string;
		deviceBrowser: string;
		sistemaOperativo: string;
		dispositivo: string;
	};
	clientSection: {
		tipoDocumentoId: string;
		numeroDocumento: string;
		nombreCortoCliente: string;
		telefonoCelular: string;
	};
	errorSection: {
		codigoError: string;
		descripcionError: string;
		codigoErrorFuncional: string;
		descripcionErrorFuncional: string;
		tipoExcepcion: string;
		servicio: string;
		operacionServicio: string;
	};
}
export interface IInfoUserForLog {
	sessionId: string;
	ipClient: string;
	pasoFuncional: string;
	documentType: string;
	documentNumber: string;
	customerName: string;
	mobilePhone: string;
  optionSelected: string;
  loanNumber: string;
}
export const initialInfoUserForLog: IInfoUserForLog = {
	sessionId: "",
	ipClient: "",
	pasoFuncional: "",
	documentType: "",
	documentNumber: "",
	customerName: "",
	mobilePhone: "",
  optionSelected: "",
  loanNumber: "",
};
