import { createAction, props } from '@ngrx/store';


export const clearMntUserData = createAction(
  '[MntUserData] Clear MntUserData'
);

export const changeDataObligation = createAction(
  '[MntUserData] Change Data of Obligation',
  props<IMntUserDataObligation>()
);

export const changeOptionSelect = createAction(
  '[MntUserData] Change OptionSelect y dateSimulation',
  props<{ optionSelect: string, dateSimulation: string }>()
);

export const changeNewCoditions = createAction(
  '[MntUserData] Change NextPaymentDate, Extension y termExtension',
  props<{ nextPaymentDate: string, extension: string, termExtension: string, installmentLifeAndUnemployment: string }>()
);

export const changeinstallmentLifeAndUnemployment = createAction(
  '[MntUserData] Change installmentLifeAndUnemployment',
  props<{ installmentLifeAndUnemployment: string }>()
);


export interface IMntUserDataObligation {
  regularInstallmentClean: string;
  expirationDate: string;
  loanNumber: string;
  loanPlanDescription: string;
}

export const initialMntUserDataObligation: IMntUserDataObligation = {
  regularInstallmentClean: "",
  expirationDate: "",
  loanNumber: "",
  loanPlanDescription: "",
}
