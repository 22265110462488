import { CanActivateFn } from "@angular/router";

export const blockForwardGuard: CanActivateFn = (route, state) => {
	bloquearNavegacionHaciaAdelante();
	return true;
};

function bloquearNavegacionHaciaAdelante(): void {
	setTimeout(() => {
		history.pushState({ bloqueado: true }, "", window.location.href);
	}, 0);

	window.onpopstate = (event) => {
		if (event.state?.bloqueado) {
			setTimeout(() => {
				history.pushState(
					{ bloqueado: true },
					"",
					window.location.href
				);
			}, 0);
		}
	};
}
