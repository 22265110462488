<div class="footer-area" *ngIf="hiddenNav">
	<div class="contenedor-mantenimientos-mobile" id="footer-mobile">
		<div class="logo" id="logo-grupo-bancolombia">
			<bc-logo size="170px" width="5rem" name="bancolombia-horizontal" [ariaHidden]="false"
			alt="logo"></bc-logo>
			<p class="copyrigth" style="padding-left: 20px;">Copyright © 2025 Grupo Bancolombia.</p>
			<div class="contenedorVigilado">
				<bc-logo class="rotarLogo" size="20px" width="5rem" name="logo-vigilado"
					[ariaHidden]="false" alt="logo"></bc-logo>
			</div>
		</div>
		<p id="direccion-ip">Dirección IP: {{ ipClient }} </p>
		<p id="ultimo-ingreso">Última visita {{ formattedLastLogin }}, {{ lastLoginHour }}</p>
	</div>

	<div class="contenedor-mantenimientos-desktop" id="footer-desktop">
		<div class="logo-copyright" id="logo-bancolombia-horizontal">
			<bc-logo size="170px" width="5rem" name="bancolombia-horizontal" [ariaHidden]="false"
				alt="logo"></bc-logo>
			<p class="copyrigth" style="padding-left: 20px;">Copyright © 2025 Grupo Bancolombia.</p>
			<div class="contenedorVigilado">
				<bc-logo class="rotarLogo" size="20px" width="5rem" name="logo-vigilado"
					[ariaHidden]="false" alt="logo"></bc-logo>
			</div>
		</div>
		<div class="ip-ultima-visita">
			<p id="direccion-ip">Dirección IP: {{ ipClient }} </p>
			<p id="ultimo-ingreso">Última visita {{ formattedLastLogin }}, {{ lastLoginHour }}</p>
		</div>
	</div>
</div>