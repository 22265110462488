import { createReducer, on, Action } from '@ngrx/store';
import { changeDataObligation, changeOptionSelect, changeinstallmentLifeAndUnemployment, changeNewCoditions, clearMntUserData } from '../actions/mntUserData.action';
import { IMntUserData, initialMntUserData } from '../states/mntUserData.state';

export const changeMntUserData = createReducer(
  initialMntUserData,
  on(clearMntUserData, (_state) => ({
    ...initialMntUserData
  })),

  on(changeDataObligation, (state, { regularInstallmentClean, expirationDate, loanNumber, loanPlanDescription }) => ({
    ...state,
    regularInstallmentClean,
    expirationDate,
    loanNumber,
    loanPlanDescription
  })),

  on(changeOptionSelect, (state, { optionSelect, dateSimulation }) => ({
    ...state,
    optionSelect,
    dateSimulation
  })),

  on(changeNewCoditions, (state, { nextPaymentDate, extension, termExtension, installmentLifeAndUnemployment }) => ({
    ...state,
    nextPaymentDate,
    extension,
    termExtension,
    installmentLifeAndUnemployment
  })),
  on(changeinstallmentLifeAndUnemployment, (state, { installmentLifeAndUnemployment }) => ({
    ...state,
    installmentLifeAndUnemployment
  }))
);


export function mntUserDataReducer(state:IMntUserData, action: Action) {
  return changeMntUserData(state, action);
}
