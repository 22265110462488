// Objeto modal con valores por defecto - se toman en caso de que no se manda objeto
export const DATA_MODAL = {
	btnAbrirM: "Texto boton abrir modal",
	typeButton: "primary",
	stylesBtn: {
		width: "302px"
	},

	iconoSe: "",
	arialLab: "",
	tamaIcon: "100px",

	titule: "Titulo del modal",

	tex1: "Texto obligatorio",
	styleText1: {},
	tex1Part2: "",
	styleText1Part2: {},
	tex1Part3: "",
	styleText1Part3: {},

	tex2: "Texto alternativo 1",
	styleText2: {},
	t2: false,

	tex3: "Texto alternativo 3",
	styleText3: {},
	t3: false,

	texBtn1: "Texto boton 1",
	pathBtn1: "",
	texBtn2: "Texto boton 2",
	pathBtn2: "",
	btn2: false,
	can: false,

	noVisibleX: false,
	outerCloseTap: true,
  closeModal:true
};

// Objeto con propiedades para la modal que se encuentra en pantalla2 boton: ¡Sí, me interesa!
export const DATAX_MODAL_PANT2 = {
	btnAbrirM: "¡Sí, me interesa!",
	typeButton: "primary", //secoundary
	stylesBtn: {
		width: "250px",
		"margin-top": "21px"
	},

	iconoSe: "te_llamamos",
	arialLab: "¡Te llamamos!",
	tamaIcon: "100px",

	titule: "¡Te llamamos!",

	tex1: `Continua el proceso atendiendo nuestra llamada. Te marcaremos al número celular`,
	styleText1: {},
	tex1Part2: "",
	styleText1Part2: {},
	tex1Part3: "",
	styleText1Part3: {},

	tex2: "Número de celular",
	styleText2: {
		"font-size": "24px",
    "font-weight": 700
	},
	t2: true,

	tex3: "Queremos estar seguros de tener tu número correcto. Confirma que el número este actualizado", // Falta inteligencia.
	styleText3: {},
	t3: true,

	texBtn1: "Llámame",
	pathBtn1: "",
	texBtn2: "Debo actualizarlo",
	pathBtn2: "",
	btn2: true,
  can: true,
	noVisibleX: false,
	outerCloseTap: true,
  closeModal:true
};

export const DATAX_MODAL_PANT2_2 = {
	btnAbrirM: "¡Sí, me interesa!",
	typeButton: "primary", //secoundary
	stylesBtn: {
		width: "302px",
		"margin-top": "21px"
	},

	iconoSe: "conoce_nuestra_app",
	arialLab: "Actualiza tus datos",
	tamaIcon: "100px",

	titule: "Queremos llamarte y no tenemos tu número",

	tex1: `Actualiza tu información de contacto. En nuestra Sucursal Virtual Personas, elige la opción`,
	styleText1: {
		display: "inline"
	},
	tex1Part2: " “Actualizar mis datos”",
	styleText1Part2: {
		"font-weight": "bold",
		display: "inline"
	},
	tex1Part3: " La encontrarás justo debajo de tu nombre.",
	styleText1Part3: {
		display: "inline"
	},

	tex2: "Luego de actualizar tu información. Abre tu App Bancolombia e ingresa de nuevo.",
	styleText2: {
		margin: "10px 0px 0px 0px"
	},
	t2: true,

	tex3: "",
	styleText3: {},
	t3: false,

	texBtn1: "Entendido",
	pathBtn1: "",
	texBtn2: "",
	pathBtn2: "",
	btn2: false,
  can: false,
	noVisibleX: false,
	outerCloseTap: true,
  closeModal:true
};


// Objeto con propiedades para la modal que se encuentra en pantalla5 boton: Debo actualizarlo
export const DATA_MODAL_ACTUALIZARLO = {
	btnAbrirM: "Debo actualizarlo",
	typeButton: "secundary",
	stylesBtn: {
		width: "302px"
	},

	iconoSe: "Copia de idea",
	arialLab: "Copia de idea",
	tamaIcon: "100px",

	titule: "¿Cómo actualizar tus datos de contacto?",

	tex1: `En nuestra Sucursal Virtual Personas, elige la opción `,
	styleText1: {
		display: "inline"
	},
	tex1Part2: `“Actualizar mis datos”.`,
	styleText1Part2: {
		"font-weight": "bold",
		display: "inline"
	},
	tex1Part3: "La encontrarás justo debajo de tu nombre.",
	styleText1Part3: {},

	tex2: "Luego de actualizar tu información. Abre tu App Bancolombia e ingresa de nuevo.",
	styleText2: {
		display: "inline"
	},
	t2: true,

	tex3: "",
	styleText3: {},
	t3: false,

	texBtn1: "Entendido",
	pathBtn1: "",
	texBtn2: "",
	pathBtn2: "",
	btn2: false,
  can: false,
	noVisibleX: false,
	outerCloseTap: true,
  closeModal:true
};

// Objeto con las propiedades para la modal que se encuentra en pantalla 3 y 4: ¡Me interesa!
export const DATAX_MODAL_PANT3_4 = {
	btnAbrirM: "¡Me interesa!",
	typeButton: "primary", //secoundary
	stylesBtn: {
		width: "250px"
	},

	iconoSe: "te_llamamos",
	arialLab: "¡Te llamamos!",
	tamaIcon: "100px",

	titule: "¡Te llamamos!",

	tex1: `Continua el proceso atendiendo nuestra llamada. Te marcaremos al número celular`,
	styleText1: {},
	tex1Part2: "",
	styleText1Part2: {},
	tex1Part3: "",
	styleText1Part3: {},

	tex2: "Número de celular",
	styleText2: {
		"font-size": "24px",
    "font-weight": 700
	},
	t2: true,

	tex3: "Queremos estar seguros de tener tu número correcto. Confirma que el número este actualizado", // Falta inteligencia.
	styleText3: {},
	t3: true,

	texBtn1: "Llámame",
	pathBtn1: "",
	texBtn2: "Debo actualizarlo",
	pathBtn2: "",
	btn2: true,
  can: true,
	noVisibleX: false,
	outerCloseTap: true,
  closeModal:true
};

// Objeto con propiedades para la modal que se encuentra en pantalla 2  boton: Cancelar o Salir
export const DATA_MODAL_TOMATE_TIEMPO = {
	btnAbrirM: "Cancelar",
	typeButton: "",
	stylesBtn: {
		width: "100px",
		background: "#f4f4f4",
		"text-decoration": "underline",
		border: "none",
		"box-shadow": "none",
    "font-size" :"17px"
	},

	iconoSe: "mano_1",
	arialLab: "mano_1",
	tamaIcon: "100px",

	titule: "¿Estas seguro que quieres Salir?",

	tex1: "Estas alternativas ",
	styleText1: {
		display: "inline"
	},
	tex1Part2: "",
	styleText1Part2: {
		"font-weight": "bold",
		display: "inline"
	},
	tex1Part3: " por si cambias de opinión.",
	styleText1Part3: {
		display: "inline"
	},

	tex2: "",
	styleText2: {},
	t2: false,

	tex3: "",
	styleText3: {},
	t3: false,

	texBtn1: "Volver",
	pathBtn1: "",
	texBtn2: "Salir",
	pathBtn2: "",
	btn2: true,
  can: false,
	noVisibleX: false,
	outerCloseTap: true,
  closeModal:true
};

// Objeto con propiedades para la modal que se encuentra en pantalla 2  boton: Cancelar o Salir
export const DATA_MODAL_TOMATE_TIEMPO_SALIR = {
	btnAbrirM: "",
	typeButton: "",
	stylesBtn: {
		width: "50px",
		background: "#fff",
		border: "none",
		"box-shadow": "none",
		"font-weight": "200",
		padding: "0px",
		margin: "0px"
	},

	iconoSe: "mano_1",
	arialLab: "mano_1",
	tamaIcon: "100px",

	titule: "¿Estas seguro que quieres Salir?",

	tex1: "",
	styleText1: {
		display: "inline"
	},
	tex1Part2: "",
	styleText1Part2: {
		"font-weight": "bold",
		display: "inline"
	},
	tex1Part3: "",
	styleText1Part3: {
		display: "inline",
		"padding-bottom": "45px"
	},

	tex2: "",
	styleText2: {},
	t2: false,

	tex3: "",
	styleText3: {},
	t3: false,

	texBtn1: "Volver",
	pathBtn1: "",
	texBtn2: "Salir",
	pathBtn2: "",
	btn2: true,
  can: false,
	noVisibleX: false,
	outerCloseTap: true,
  closeModal:true
};

// Objeto con propiedades para la modal que se encuentra en pantalla 3, 4  boton: Cancelar o Salir
export const DATA_MODAL_TOMATE_TIEMPO_PANT3_4 = {
	btnAbrirM: "Cancelar",
	typeButton: "",
	stylesBtn: {
		width: "100px",
		background: "#fff",
		"text-decoration": "underline",
		border: "none",
		"box-shadow": "none"
	},

	iconoSe: "mano_1",
	arialLab: "mano_1",
	tamaIcon: "100px",

	titule: "¿Estas seguro que quieres Salir?",

	tex1: "Estas alternativas ",
	styleText1: {
		display: "inline"
	},
	tex1Part2: "estarán esperando por ti hasta el 00/00/00",
	styleText1Part2: {
		"font-weight": "bold",
		display: "inline"
	},
	tex1Part3: " por si cambias de opinión.",
	styleText1Part3: {
		display: "inline"
	},

	tex2: "",
	styleText2: {},
	t2: false,

	tex3: "",
	styleText3: {},
	t3: false,

	texBtn1: "Volver",
	pathBtn1: "",
	texBtn2: "Salir",
	pathBtn2: "https://www.bancolombia.com/personas",
	btn2: true,
  can: false,
	noVisibleX: false,
	outerCloseTap: true,
  closeModal:true
};

// Objeto con propiedades para la modal que se encuentra en pantalla 3, 4  boton: Cancelar o Salir
export const DATA_MODAL_SESSION_DUPLICATE = {
	btnAbrirM: "",
	typeButton: "",
	stylesBtn: {},

	iconoSe: "mano_5",
	arialLab: "mano_5",
	tamaIcon: "100px",

	titule: "¡Espera!",

	tex1: "Has abierto otra sesión, esta se cerrará y podrás continuar con la otra.",
	styleText1: {
		display: "inline"
	},
	tex1Part2: "",
	styleText1Part2: {},
	tex1Part3: "",
	styleText1Part3: {},

	tex2: "",
	styleText2: {},
	t2: false,

	tex3: "",
	styleText3: {},
	t3: false,

	texBtn1: "SALIR",
	pathBtn1: "",
	texBtn2: "",
	pathBtn2: "",
	btn2: false,
  can: false,
	noVisibleX: true,
	outerCloseTap: false,
  closeModal:true
};
export const DATA_MODAL_TEN_EN_CUENTA = {
	btnAbrirM: "",
	typeButton: "",
	stylesBtn: {
		width: "100px",
		background: "#f4f4f4",
		"text-decoration": "underline",
		border: "none",
		"box-shadow": "none"
	},

	iconoSe: "mano_1",
	arialLab: "mano_1",
	tamaIcon: "100px",

	titule: "¡Ten en cuenta!",

	tex1: "Debes firmar los mismos documentos",
	styleText1: {
		display: "inline"
	},
	tex1Part2: "por cada opción.",
	styleText1Part2: {	},
	tex1Part3: "",
	styleText1Part3: {
		display: "inline"
	},

	tex2: "",
	styleText2: {},
	t2: false,

	tex3: "",
	styleText3: {},
	t3: false,

	texBtn1: "Continuar",
	pathBtn1: "",
	texBtn2: "",
	pathBtn2: "",
	btn2: false,
  can: false,
	noVisibleX: true,
	outerCloseTap: true,
  closeModal:true
};
export const DATA_MODAL_UTILIZAR_MISMOS_DATOS = {
	btnAbrirM: "",
	typeButton: "",
	stylesBtn: {
		width: "100px",
		background: "#f4f4f4",
		"text-decoration": "underline",
		border: "none",
		"box-shadow": "none"
	},

	iconoSe: "mano_5",
	arialLab: "mano_5",
	tamaIcon: "100px",

	titule: "¿Quieres continuar con los datos de los beneficiarios que ya usaste?",

	tex1: "",
	styleText1: {
		display: "inline"
	},
	tex1Part2: "",
	styleText1Part2: {	},
	tex1Part3: "",
	styleText1Part3: {
		display: "inline"
	},

	tex2: "",
	styleText2: {},
	t2: false,

	tex3: "",
	styleText3: {},
	t3: false,

	texBtn1: "Usar los datos",
	pathBtn1: "",
	texBtn2: "Son diferentes",
	pathBtn2: "",
	btn2: true,
  can: false,
	noVisibleX: true,
	outerCloseTap: true,
  closeModal:true
};
export const DATA_MODAL_TEN_EN_CUENTA_ADVERTENCIA = {
	btnAbrirM: "",
	typeButton: "",
	stylesBtn: {
		width: "100px",
		background: "#f4f4f4",
		"text-decoration": "underline",
		border: "none",
		"box-shadow": "none"
	},

	iconoSe: "mano_5",
	arialLab: "mano_5",
	tamaIcon: "100px",

	titule: "Ten en cuenta",

	tex1: "Al hacer clic en continuar no podrás cambiar los datos ingresados",
	styleText1: {
		display: "inline"
	},
	tex1Part2: "",
	styleText1Part2: {	},
	tex1Part3: "",
	styleText1Part3: {
		display: "inline"
	},

	tex2: "",
	styleText2: {},
	t2: false,

	tex3: "",
	styleText3: {},
	t3: false,

	texBtn1: "Continuar",
	pathBtn1: "",
	texBtn2: "Volver",
	pathBtn2: "",
	btn2: true,
  	can: false,
	noVisibleX: true,
	outerCloseTap: true,
  	closeModal:true
};
export const DATA_MODAL_SOLO_SELECCIONASTE_UNA_ALTERNATIVA = {
	btnAbrirM: "",
	typeButton: "",
	stylesBtn: {
		width: "1px",
		background: "#f4f4f4",
		"text-decoration": "underline",
		border: "none",
		"box-shadow": "none"
	},

	iconoSe: "mano_5",
	arialLab: "mano_5",
	tamaIcon: "100px",

	titule: "Solo seleccionaste una alternativa",

	tex1: "¿Quieres continuar el proceso sin",
	styleText1: {
		display: "inline"
	},
	tex1Part2: "seleccionar las demás?",
	styleText1Part2: {},
	tex1Part3: "",
	styleText1Part3: {},

	tex2: "",
	styleText2: {},
	t2: false,

	tex3: "",
	styleText3: {},
	t3: false,

	texBtn1: "Continuar",
	pathBtn1: "",
	texBtn2: "Volver",
	pathBtn2: "",
	btn2: true,
  can: false,
	noVisibleX: true,
	outerCloseTap: true,
  closeModal:true
};
export const DATA_MODAL_CANCELAR_NEGOCIACION = {
	btnAbrirM: "Cancelar",
	typeButton: "",
	stylesBtn: {
		width: "100px",
		background: "#f4f4f4",
		"text-decoration": "underline",
		border: "none",
		"box-shadow": "none"
	},

	iconoSe: "mano_1",
	arialLab: "mano_1",
	tamaIcon: "100px",

	titule: "¿Estas seguro que quieres salir?",

	tex1: "Retoma el proceso cuando quieras.",
	styleText1: {
		display: "inline"
	},
	tex1Part2: "Guardaremos tu información.",
	styleText1Part2: {	},
	tex1Part3: "",
	styleText1Part3: {
		display: "inline"
	},

	tex2: "",
	styleText2: {},
	t2: false,

	tex3: "",
	styleText3: {},
	t3: false,

	texBtn1: "Cancelar",
	pathBtn1: "",
	texBtn2: "Salir",
	pathBtn2: "",
	btn2: true,
  can: false,
	noVisibleX: true,
	outerCloseTap: true,
  closeModal:true
};

export const DATA_MODAL_CANCELAR_NEGOCIACION_FIRMA = {
	btnAbrirM: "Cancelar",
	typeButton: "",
	stylesBtn: {
		width: "100px",
		background: "#fff",
		"text-decoration": "underline",
		border: "none",
		"box-shadow": "none"
	},

	iconoSe: "mano_1",
	arialLab: "mano_1",
	tamaIcon: "100px",

	titule: "¿Estas seguro que quieres salir?",

	tex1: "Retoma el proceso cuando quieras.",
	styleText1: {
		display: "inline"
	},
	tex1Part2: "Guardaremos tu información.",
	styleText1Part2: {	},
	tex1Part3: "",
	styleText1Part3: {
		display: "inline"
	},

	tex2: "",
	styleText2: {},
	t2: false,

	tex3: "",
	styleText3: {},
	t3: false,

	texBtn1: "Cancelar",
	pathBtn1: "",
	texBtn2: "Salir",
	pathBtn2: "",
	btn2: true,
  can: false,
	noVisibleX: true,
	outerCloseTap: true,
  closeModal:true
};

export const DATA_MODAL_PARA_CONTINUAR = {
	btnAbrirM: "",
	typeButton: "",
	stylesBtn: {
		width: "100px",
		background: "#f4f4f4",
		"text-decoration": "underline",
		border: "none",
		"box-shadow": "none"
	},

	iconoSe: "mano_5",
	arialLab: "mano_5",
	tamaIcon: "100px",

	titule: "Para continuar",

	tex1: "",
	styleText1: {
		display: "inline"
	},
	tex1Part2: "",
	styleText1Part2: {	},
	tex1Part3: "",
	styleText1Part3: {
		display: "inline"
	},

	tex2: "",
	styleText2: {},
	t2: false,

	tex3: "",
	styleText3: {},
	t3: false,

	texBtn1: "Volver",
	pathBtn1: "",
	texBtn2: "",
	pathBtn2: "",
	btn2: false,
  	can: false,
	noVisibleX: true,
	outerCloseTap: true,
  	closeModal:true
};
